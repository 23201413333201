import React, { useState, useEffect, useRef } from "react"; // Import useRef
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  Paper,
  Typography,
  CssBaseline,
  Grid,
  Box,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import dayjs from "dayjs";
import InputAdornment from "@mui/material/InputAdornment";
import { ToastContainer, toast } from "react-toastify";
import apiService from "../services/ApiService";
import "react-toastify/dist/ReactToastify.css";
import { countries } from "../pages/countries";

// Custom dark theme with Material UI
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9",
    },
    secondary: {
      main: "#f48fb1",
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
    },
    text: {
      primary: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Arial",
    h5: {
      fontWeight: 600,
      color: "#ffffff",
    },
  },
});

const Form = () => {
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    dateOfBirth: null,
    gender: "",
    department: "",
    position: "",
    country: "",
    cv: null,
  });
  const [phone, setPhone] = useState("");
  const fileInputRef = useRef(null); // Create a ref for the file input

  // Fetch departments on mount
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await apiService.get("/api/v1/departments");
        if (response.data.success) {
          setDepartments(response.data.departments);
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  const handleDepartmentChange = async (event) => {
    const departmentId = event.target.value;

    setFormData({
      ...formData,
      department: departmentId,
      position: "",
    });

    try {
      const response = await apiService.get(
        `/api/v1/positions?departmentId=${departmentId}`
      );
      if (response.data.success) {
        setPositions(response.data.positions);
      }
    } catch (error) {
      console.error("Error fetching positions:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData();

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (key === "cv" && formData[key] instanceof File) {
          fd.append(key, formData[key], formData[key].name);
        } else {
          fd.append(key, formData[key]);
        }
      }
    }

    if (formData.country && phone) {
      fd.append("phoneNumber", `+${countries[formData.country].code}${phone}`);
    }

    try {
      const response = await apiService.post("/api/v1/users/create", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.success) {
        toast.success("Form submitted successfully!");

        // Clear form data after successful submission
        setFormData({
          name: "",
          email: "",
          dateOfBirth: null,
          gender: "",
          department: "",
          position: "",
          country: "",
          cv: null,
        });
        setPhone(""); // Reset the phone number
        setPositions([]); // Reset positions
        fileInputRef.current.value = null; // Clear the file input
      } else {
        toast.error("Form submission failed!");
      }
    } catch (error) {
      toast.error("Error submitting form!");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundImage: `url("your-background-image.jpg")`,
          backgroundSize: "cover",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={6}
          sx={{
            padding: 4,
            width: "100%",
            maxWidth: 700,
            bgcolor: "background.paper",
          }}
        >
          <Typography variant="h5" gutterBottom align="center">
            Personal Information
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  fullWidth
                  name="name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Email"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  required
                  type="email"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="country"
                    value={formData.country}
                    onChange={(e) =>
                      setFormData({ ...formData, country: e.target.value })
                    }
                    required
                  >
                    {Object.entries(countries).map(([code, { name }]) => (
                      <MenuItem key={code} value={code}>
                        {name} (+{code})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone Number"
                  fullWidth
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {formData.country &&
                          `+${countries[formData.country].code}`}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    value={formData.dateOfBirth}
                    onChange={(newDate) =>
                      setFormData({ ...formData, dateOfBirth: newDate })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    maxDate={dayjs()}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="gender"
                    value={formData.gender}
                    onChange={(e) =>
                      setFormData({ ...formData, gender: e.target.value })
                    }
                    required
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Department</InputLabel>
                  <Select
                    name="department"
                    value={formData.department}
                    onChange={handleDepartmentChange}
                    required
                  >
                    {departments.map((dept) => (
                      <MenuItem key={dept._id} value={dept._id}>
                        {dept.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Position</InputLabel>
                  <Select
                    name="position"
                    value={formData.position}
                    onChange={(e) =>
                      setFormData({ ...formData, position: e.target.value })
                    }
                    disabled={!positions.length} // Disable if no positions
                    required
                  >
                    {positions.map((pos) => (
                      <MenuItem key={pos._id} value={pos._id}>
                        {pos.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="file"
                  fullWidth
                  inputProps={{ accept: ".pdf, .doc, .docx" }}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      cv: e.target.files[0],
                    })
                  }
                  inputRef={fileInputRef} // Assign the ref to the file input
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default Form;
