import logo from "./logo.svg";
import "./App.css";
import Form from "../src/pages/form";

function App() {
  return (
    <>
      <Form />
    </>
  );
}

export default App;
